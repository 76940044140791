<template>
  <b-container fluid class="px-0">

    
    <b-row id="pricing" class="justify-content-center mx-0 p-1"> 
      <h2 class="sectitle">Subscribe Now! Cancel at Anytime! 0 Fees!</h2>
    </b-row>

    <b-row class="justify-content-center" no-gutters>
      <div class="text-white">
        <b-form-checkbox v-model="checkedYear" name="check-button" @input="onCheckedYear" switch>
          Annually pricing
        </b-form-checkbox>
      </div>
    </b-row>

    <b-row class="justify-content-center mb-3" no-gutters>
        <b-col v-bind:key='product.id' v-for="product in getMonthlyPrices" xs="12" md="4" class="p-2 d-flex justify-content-center">
          <b-card
          no-body
          style="width: 100%; background-color: #7930ff;"
          >
            <template #header>
              <h6 v-show="!checkedYear" class="mb-0 text-center">{{product.name.replace('Regular','')}}</h6>
              <h4 v-show="!checkedYear" class="m-2 text-center">${{product.unit_price.amount/100}}</h4>
              <h6 v-show="checkedYear" class="mb-0 text-center">{{getAnnuallyPrices[getMonthlyPrices.indexOf(product)].name.replace('Regular','')}}</h6>
              <h4 v-show="checkedYear" class="m-2 text-center">${{getAnnuallyPrices[getMonthlyPrices.indexOf(product)].unit_price.amount/100}}</h4>
              <p v-show="checkedYear" class="m-2 text-center">(${{getAnnuallyPrices[getMonthlyPrices.indexOf(product)].unit_price.amount/1200}}/month)</p>
            </template>
            <b-list-group  flush>
                <b-list-group-item v-if="product.trial_period!==null" style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                  <p class="mb-0 text-center text-light">Trial Period</p>
                  <h6 class="mb-0 text-center">Free {{product.trial_period.frequency}} {{product.trial_period.interval}}s trial</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Monthly Videos</p>
                    <h6 class="mb-0 text-center">{{product.packVideoLimit}}</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Add your Logo</p>
                    <h6 class="mb-0 text-center">{{product.logo}}</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Ai Script</p>
                    <h6 class="mb-0 text-center">{{product.aiscripting}}</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Ai Voice-over</p>
                    <h6 class="mb-0 text-center">{{product.aivoice}}</h6>
                </b-list-group-item>
                <b-list-group-item style="background-color: #5C66F7; color: #ffffff; font-size: 0.8em;">
                    <p class="mb-0 text-center">Background Music</p>
                    <h6 class="mb-0 text-center">{{product.backmusic}}</h6>
                </b-list-group-item>
                
            </b-list-group>
            <b-card-body>
                <b-button 
                v-if="getMonthlyPrices.indexOf(product)==1"
                class="w-100 mt-2" 
                size="col-md-4"         
                squared 
                :id="'boost-'+getMonthlyPrices.indexOf(product)" 
                variant="warning"
                @click.prevent="ordernow(product.id, getMonthlyPrices.indexOf(product))"
                >Order Now</b-button>

                <b-button 
                v-else
                class="w-100 mt-2" 
                size="sm"           
                squared 
                :id="'boost-'+getMonthlyPrices.indexOf(product)" 
                variant="primary"
                @click.prevent="ordernow(product.id, getMonthlyPrices.indexOf(product))"
                >Order Now</b-button>
            </b-card-body>
        </b-card>
        </b-col>
      
    </b-row>
    <b-row class="justify-content-center pt-4 mt-4 mb-4 p-1" no-gutters>
        <div v-show="showDiscountCode" style="background-color:red; border-radius: 5px; font-size: 1.2em; padding: 10px; text-align: center;">Congratulations!<br><b-icon icon="gift" font-scale="3" animation="throb" aria-hidden="true"></b-icon><br> You got 20% OFF! <br> Copy this Code: <span style="font-size: 1.3em bold; color: yellow;">BQDFNSSQA3</span><br>Use it in Checkout Page</div>
        <b-button
                v-show= "!showDiscountCode"
                class="p-4"
                size="md"   
                variant="danger"
                @click.prevent="onShowDiscountCode"
                ><b-icon icon="gift" font-scale="3" animation="fade" aria-hidden="true"></b-icon><br>Click Here! Get a Discount Code</b-button>
    </b-row>

  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
   metaInfo: {
      title: "Speechor-Ai-Video-Transcriber",
      meta: [
          {
            vmid: "description", 
            name: "description", 
            content: "Speechor Ai video transcriber transcribes any video and generates Ai videos with animated text subtitles overlayed on the video. You can generate Ai transcribed reels, stories, shorts in few seconds from any video"
          }
        ],
   },
  data() {
    return {
      cancelUrl: 'https://speechor.com/Transcriber_Pricing',
      checkedYear:false,
      billingCycle: 'month',
      showDiscountCode: false
    };
  },
  components: { 
  },

  computed: {
    ...mapGetters(["getMonthlyPrices" , "getAnnuallyPrices"])
  },

  mounted() {
      this.$store.dispatch("getPaddlePrices", {moduleReference: "Transcriber"});  
  },
  
  methods:{

    onCheckedYear(){

      if (this.checkedYear){
        this.billingCycle = 'year';
      } else {
        this.billingCycle = 'month';
      }

    },


    onShowDiscountCode(){
      this.showDiscountCode = true;
    },    

    ordernow(id, index){
      this.$router.push({ path: '/Checkout', query: {mp: id, ap: this.$store.state.paddleAnnuallyPrices[index].id, bc: this.billingCycle, curl: this.cancelUrl} } ).catch(()=>{});
   
    },

  },

  
};
</script>
<style scoped>

  /*.samples-bg{
    background-image: url("../assets/Speechor-ai-video-generator-1280.png");
    background-position: top;
    background-repeat: repeat-y;
    background-size: contain;
    opacity: 1;
  }*/

 .border-left{
  background: #482b69;
  border: none;
 }
 .sectitle{
  color: #ffba00;
  text-align: center;
 }
 
 .whitetitle{
  color: #fff;
  text-align: center;
 }
 .price-style{
  text-align: center;
  font-family: 'almendra display',Sans-serif;
  font-weight: 700; 
}
.bg-trans{
  background: transparent;
  border: none;
  padding: 10px 20px;
}
.bg-trans-p6{
  background: transparent;
  border: none;
  padding: 5px 20px;
}
.txt-grey{
  color: white;
  font-size: 12px;
}
.txt-grey1{
  color: grey;
  font-size: 12px;
}
.iconpostion{
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.bg-orge{
  background-color: #ff9400;
}
.bg-blue1{
  background-color: #007aff;
}
.bg-puplea{
  background-color: #6f42c1;
}
.bg-pupleb{
  background-color: #c7a2fc;
}
.bg-greenb{
background-color: #fff;
}
.bg-img{
  background-color: transparent;
  background-image: linear-gradient(140deg,#1A1060 17%,#f2295b 100%);
}

.bg-grad-info{
  background: rgb(0,102,216);
  background: linear-gradient(133deg, rgba(0,102,216,1) 41%, rgba(8,71,133,1) 55%);
}
.bg-grad-warning{
  background: rgb(236,177,0);
  background: linear-gradient(133deg, rgba(236,177,0,1) 41%, rgba(213,121,0,1) 55%);
}

.bag-grad-1{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(75, 0, 130, 1)41%, rgba(114, 14, 158, 1) 55%);
}
.bg-grad-2{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(114, 14, 158, 1)41%, rgba(128, 0, 128, 1)55%);
}
.bg-grad-3{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(69, 44, 99, 1)41%, rgba(128, 0, 128, 1)55%);
}
.bg-grad-4{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(69, 44, 99, 1)41%, rgba(51, 0, 111, 1)55%);
}

.custom_mb {
  margin-bottom: 50px;
}

@media not screen and (max-width: 767px) {
  .padd-x{
    padding-right: 10%;
    padding-left: 10%;
  }
}

</style>