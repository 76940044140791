<template>
  <b-container fluid class="px-0">

    <b-row class="justify-content-center mx-0 p-1">
      <h1 style="text-align: center;">Speechor Ai Video Generator</h1>
    </b-row>
    <b-row class="justify-content-center mx-0 p-1"> 
      <h2 class="sectitle">Generate and Transcribe Videos With Animated Text</h2>
    </b-row>
    
    <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-2">
          <b-embed 
          type="video"
          aspect="16by9"
          controls
          autoplay
          poster="https://speechormanager.univerhome.com/uploads/media/transcriber_thimb.jpg">     
          <source src="https://speechormanager.univerhome.com/uploads/media/transcriber-19-6.mp4" type="video/mp4">
          </b-embed>
        </b-col>
    </b-row>

    <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>

    <b-row class="justify-content-center mt-2 mb-0 p-1" no-gutters> 
        <h1 class="whitetitle">Create Stunning Kids Stories, Quotes, Documentaries!<br></h1>
    </b-row>
    <b-row class="justify-content-center mx-0 p-1"> 
      <h2 class="whitetitle">Produce your content without being a Pro Author or Artist</h2>
    </b-row>
    <b-row class="justify-content-center mx-0 p-1"> 
      <h4 class="sectitle">Speechor Will Write the content, generate the Voice over with a Background music! Then produce your video!</h4>
    </b-row>
    <b-row class="justify-content-center pt-4 pr-4 pl-4" no-gutters>
        <b-col xs="12" md="4" class="p-4 pb-4">
          <video width="100%" controls preload="none" poster="https://speechormanager.univerhome.com/uploads/media/speechor-ai-video-v-thumb.png">
            <source src="https://speechormanager.univerhome.com/uploads/media/speechor-ai-video-generator-v.mp4" type="video/mp4">
          </video>
        </b-col>
        <b-col xs="12" md="4" class="p-4 pb-4">
          <video width="100%" controls preload="none" poster="https://speechormanager.univerhome.com/uploads/media/speechor-ai-video-v-thumb-1.png">
            <source src="https://speechormanager.univerhome.com/uploads/media/speechor-ai-video-generator-v-1.mp4" type="video/mp4">
          </video>
        </b-col>
        <b-col xs="12" md="4" class="p-4 pb-4">
          <video width="100%" controls preload="none" poster="https://speechormanager.univerhome.com/uploads/media/speechor-ai-video-v-thumb-2.png">
            <source src="https://speechormanager.univerhome.com/uploads/media/speechor-ai-video-generator-v-2.mp4" type="video/mp4">
          </video>
        </b-col>
    </b-row>

    <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>
    <b-row class="justify-content-center mt-2 mb-0 p-1" no-gutters> 
        <h1 class="whitetitle">Don't Waste More Time Editing<br></h1>
    </b-row>
    <b-row class="justify-content-center mx-0 p-1"> 
      <h2 class="whitetitle">Produce Ready Transcribed Reels, Shorts, Stories with Few Clicks</h2>
    </b-row>

    <b-row class="justify-content-center bg-img" no-gutters>
        <b-col xs="12" md="8" class="p-4">
          <h2 class="whitetitle mb-4">Sign Up Now!</h2>
          <div class="p-4" >
            <b-button  @click.prevent="SignUp"  block pill variant="warning" size="lg" >
              <span>Create My Account</span>
            </b-button>
          </div>
        </b-col>     
    </b-row>
    <b-row class="justify-content-center mt-2 mb-2" no-gutters>
      <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
    </b-row>

    <b-row class="justify-content-center pt-4 mt-4 mb-4 p-1" no-gutters>
        <h1 class="whitetitle">Ai Video Generator Features</h1>
      </b-row>

      <b-row no-gutters>
        
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="blockquote-left" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">AI Script Generator</h4>
              <p class="whitetitle">
              No need of writing the script yourself, Speechor Ai Video Generator will generate an accurate AI script about any idea you have in your mind,
            Just give the idea and the script will be written in few seconds</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="mic" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">AI Voice-over</h4>
              <p class="whitetitle">
              Speechor Ai Video Generator will transform your script into a human sounding AI voice over, Supporting 34 languages, and 133 voices, all voices are available for all subscription plans</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="file-music" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Backgound Music</h4>
              <p class="whitetitle">
              Speechor Ai Video Generator allows you to add a background music behind your voice over to make your produced video looks and sound 100% professional, and ready to be posted without additional editing</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="file-earmark-image" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Watermark Logo</h4>
              <p class="whitetitle">
                The Ai Video Generator can add your logo as a watermark automatically. To protect your video you can upload an image of your logo or brand, the software will add it as a watermark on top of your generated video (Available for Medium and Golden plans)
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="file-earmark-play" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Auto Editing</h4>
              <p class="whitetitle">
              You can use the Ai Video Generator without any video editing experience, the software will auto edit your videos and produce stunning Reels, Shorts ..., and even normal Youtube videos, ready to be uploaded to your social media platforms</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4"  class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-2 bag-grad-1"
          >
            <b-card-text>
              <div  style="font-size: 7rem; text-align: center;"><b-icon icon="file-richtext" class="rounded-circle bg-grad-info p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">Formatting & Languages</h4>
              <p class="whitetitle">   
               You have a large list of options to customize your output video. Choose the video format and appearance, the text font, size, position, colors... You have multiple formatting choices to generate completely a different video each time</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row> 

      <b-row class="justify-content-center mt-4 mb-4 p-1" no-gutters>
        <h1 class="whitetitle">Speechor Ai Video Generator Will get You Covered in 3 Steps!</h1>
        
      </b-row>
  
      <b-row no-gutters>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
           <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center "
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="cloud-upload" class="rounded-circle bg-grad-warning p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">1st Step</h4>
              <p>Upload the main input media (video or images), add a logo if you want</p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="toggles" class="rounded-circle bg-grad-warning p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">2nd Step</h4>
              <p>Generate a script, Select a voice, add a background music and format your output appearance</p>
            </b-card-text>
          </b-card>
        </b-col>
      
      <b-col xs="12" md="4" class="p-4 d-flex justify-content-center">
          <b-card
            style="max-width: 25rem;"
            class="mb-4 bag-grad-1 text-center"
            align="center"
          >
            <b-card-text>
              <div  style="font-size: 7rem;"><b-icon icon="check2-circle" class="rounded-circle bg-grad-warning p-4" variant="light"></b-icon></div>
              <h4 class="sectitle">3rd Step</h4>
              <p>Click Generate, Download your generated reels, shorts... Share on your social media platforms</p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

     

      <b-row class="justify-content-center mt-2 mb-2" no-gutters>
        <b-icon icon="chevron-double-down" variant="warning" font-scale="4" animation="cylon-vertical" aria-hidden="true"></b-icon>
      </b-row>
      <b-row class="justify-content-center mt-4 mb-4 p-1" no-gutters>
        <h1 class="whitetitle">Now you can start generating your own videos! <br> No more wasted time on video editors!</h1> 
      </b-row>
      <b-row class="justify-content-center mx-0 p-1" no-gutters > 
        <h2 class="sectitle">Speed up your content Creation!</h2>
      </b-row>

      <b-row class="justify-content-center bg-img" no-gutters>
        <b-col xs="12" md="8" class="p-4">
          <h2 class="whitetitle mb-4">Sign Up Now!</h2>
          <div class="p-4" >
            <b-button  @click.prevent="SignUp"  block pill variant="warning" size="lg" >
              <span>Create My Account</span>
            </b-button>
          </div>
        </b-col>   
       
      </b-row>

  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
   metaInfo: {
      title: "Speechor-Ai-Video-Transcriber",
      meta: [
          {
            vmid: "description", 
            name: "description", 
            content: "Speechor Ai video transcriber transcribes any video and generates Ai videos with animated text subtitles overlayed on the video. You can generate Ai transcribed reels, stories, shorts in few seconds from any video"
          }
        ],
   },
  data() {
    return {
      cancelUrl: 'https://speechor.com/Subscription_Video_Transcriber',
      checkedYear:false,
      billingCycle: 'month',
      visible: false,
      samplevVisible: false,
      accessWithPayment:false,
      showDiscountCode: false
    };
  },
  components: { 
  },

  computed: {
    ...mapGetters(["getMonthlyPrices" , "getAnnuallyPrices"])
  },

  mounted() {
      this.$store.dispatch("getPaddlePrices", {moduleReference: "Transcriber"});  
  },
  
  methods:{

    customOffer(){
      this.$router.push('/Askus');
    },

   
    SignUp(){
      window.location.replace('https://speechorapp.univerhome.com/Signup?r=');
    },

    onCheckedYear(){

      if (this.checkedYear){
        this.billingCycle = 'year';
      } else {
        this.billingCycle = 'month';
      }

    },

    callback(visible) {
      if (visible) {
      
        this.samplevVisible = true;
      }
    },

    onShowDiscountCode(){
      this.showDiscountCode = true;
    },    

    ordernow(id, index){
      this.$router.push({ path: '/Checkout', query: {mp: id, ap: this.$store.state.paddleAnnuallyPrices[index].id, bc: this.billingCycle, curl: this.cancelUrl} } ).catch(()=>{});
   
    },

  },

  
};
</script>
<style scoped>

  /*.samples-bg{
    background-image: url("../assets/Speechor-ai-video-generator-1280.png");
    background-position: top;
    background-repeat: repeat-y;
    background-size: contain;
    opacity: 1;
  }*/

 .border-left{
  background: #482b69;
  border: none;
 }
 .sectitle{
  color: #ffba00;
  text-align: center;
 }
 
 .whitetitle{
  color: #fff;
  text-align: center;
 }
 .price-style{
  text-align: center;
  font-family: 'almendra display',Sans-serif;
  font-weight: 700; 
}
.bg-trans{
  background: transparent;
  border: none;
  padding: 10px 20px;
}
.bg-trans-p6{
  background: transparent;
  border: none;
  padding: 5px 20px;
}
.txt-grey{
  color: white;
  font-size: 12px;
}
.txt-grey1{
  color: grey;
  font-size: 12px;
}
.iconpostion{
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.bg-orge{
  background-color: #ff9400;
}
.bg-blue1{
  background-color: #007aff;
}
.bg-puplea{
  background-color: #6f42c1;
}
.bg-pupleb{
  background-color: #c7a2fc;
}
.bg-greenb{
background-color: #fff;
}
.bg-img{
  background-color: transparent;
  background-image: linear-gradient(140deg,#1A1060 17%,#f2295b 100%);
}

.bg-grad-info{
  background: rgb(0,102,216);
  background: linear-gradient(133deg, rgba(0,102,216,1) 41%, rgba(8,71,133,1) 55%);
}
.bg-grad-warning{
  background: rgb(236,177,0);
  background: linear-gradient(133deg, rgba(236,177,0,1) 41%, rgba(213,121,0,1) 55%);
}

.bag-grad-1{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(75, 0, 130, 1)41%, rgba(114, 14, 158, 1) 55%);
}
.bg-grad-2{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(114, 14, 158, 1)41%, rgba(128, 0, 128, 1)55%);
}
.bg-grad-3{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(69, 44, 99, 1)41%, rgba(128, 0, 128, 1)55%);
}
.bg-grad-4{
  background: rgb(110,0,183);
  background: linear-gradient(133deg, rgba(69, 44, 99, 1)41%, rgba(51, 0, 111, 1)55%);
}

.custom_mb {
  margin-bottom: 50px;
}

@media not screen and (max-width: 767px) {
  .padd-x{
    padding-right: 10%;
    padding-left: 10%;
  }
}

</style>