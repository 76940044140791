<template>
  <div>
    <b-navbar id="topheader" toggleable="md" type="dark" variant="transparent">
      <b-navbar-brand  to="/">
        <img src="../assets/logo.png"   height="75" alt="Speechor">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item class = "txt-head" to="/">Home</b-nav-item>
          <b-nav-item class = "txt-head" to="/Subscription_Video_Transcriber">Video-Transcriber</b-nav-item>
          
          <b-nav-item class = "txt-head" v-show="$route.name!=='Subscription_Video_Transcriber' && $route.name!=='Checkout' && $route.name!=='Transcriber_Pricing'" href="https://discord.gg/GAXh9yMvJb">Discord</b-nav-item>
          <b-nav-item class = "txt-head" v-show="$route.name=='Subscription_Video_Transcriber' || $route.name=='Checkout' || $route.name=='Transcriber_Pricing'" to="/Transcriber_Pricing">Pricing</b-nav-item>
          <b-nav-item class = "txt-head" to="/Askus">Support</b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    
  </div>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  computed: {
   
  }
  
};
</script>

<style scoped>
#nav a.router-link-exact-active {
  color: white;
}

@media not screen and (max-width: 767px) {
  #topheader{
    padding-right: 5%;
    padding-left: 5%;
  }
}

#nav-brand{
  margin-left: 30px;
}

.txt-head{
  display: block;
  font-size: 1em;
  margin-top: 0.45em;
  margin-bottom: 0.45em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 200;
}

</style>